var $ = window.jQuery;

require('bootstrap-sass/assets/javascripts/bootstrap/collapse');
require('bootstrap-sass/assets/javascripts/bootstrap/transition');


function windowLoad() {
  window.console.log('window loaded');
}

function documentReady() {
  window.console.log('document ready');
}

$(window).load(windowLoad);
$(document).ready(documentReady);
